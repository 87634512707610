import { createSlice, dynamicPage } from '@loopbound-ares/microfrontend';

let Layout = dynamicPage(async () => (await import('./layout')).ProductLayout);

let HomeLayout = dynamicPage(async () => (await import('./(home)/layout')).HomeLayout);
let HomePage = dynamicPage(async () => (await import('./(home)/home')).HomePage);
let DocumentPage = dynamicPage(async () => (await import('./(home)/document')).DocumentPage);

let HeaderLayout = dynamicPage(async () => (await import('./(header)/layout')).HeaderLayout);
let OtherPage = dynamicPage(async () => (await import('./(header)/other')).OtherPage);

let DeveloperLayout = dynamicPage(
  async () => (await import('./developer/layout')).DeveloperLayout
);
let ApiKeysPage = dynamicPage(async () => (await import('./developer/apiKeys')).ApiKeysPage);
let ApiRequestsPage = dynamicPage(
  async () => (await import('./developer/requests')).ApiRequestsPage
);
let DeveloperOverviewPage = dynamicPage(
  async () => (await import('./developer/overview')).DeveloperOverviewPage
);
let ApiEventsPage = dynamicPage(
  async () => (await import('./developer/events')).ApiEventsPage
);

export let productSlice = createSlice([
  {
    path: ':instanceId',
    element: <Layout />,

    children: [
      {
        path: '',
        element: <HomeLayout />,

        children: [
          {
            path: '',
            element: <HomePage />
          },
          {
            path: 'document/:documentId',
            element: <DocumentPage />
          }
        ]
      },

      {
        path: 'developer',
        element: <DeveloperLayout />,

        children: [
          {
            path: '',
            element: <DeveloperOverviewPage />
          },
          {
            path: 'api-keys',
            element: <ApiKeysPage />
          },
          {
            path: 'requests',
            element: <ApiRequestsPage />
          },
          {
            path: 'events',
            element: <ApiEventsPage />
          }
        ]
      },

      {
        path: 'other',
        element: <HeaderLayout />,

        children: [
          {
            path: '',
            element: <OtherPage />
          }
        ]
      }
    ]
  }
]);
