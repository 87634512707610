export let config = {
  urls: {
    landing: 'https://loopbound.com',
    dashboard: import.meta.env.VITE_FRONTEND_URL,
    rpc: import.meta.env.VITE_RPC_URL
  },

  product: {
    id: 'skeleton',
    name: 'Skeleton'
  }
};
