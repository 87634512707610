export let Logo = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_20)">
      <rect
        x="28.471"
        y="33.2111"
        width="81.7238"
        height="38.1378"
        rx="19.0689"
        transform="rotate(20 28.471 33.2111)"
        fill="#8855DD"
      />
      <rect
        x="7.90475"
        y="3.24628"
        width="80.8521"
        height="37.731"
        rx="18.8655"
        transform="rotate(20 7.90475 3.24628)"
        fill="#F7B731"
      />
      <mask id="mask0_1_20" maskUnits="userSpaceOnUse" x="-4" y="4" width="88" height="62">
        <rect
          x="8.04388"
          y="3.24628"
          width="81.7238"
          height="38.1378"
          rx="5"
          transform="rotate(20 8.04388 3.24628)"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_1_20)">
        <rect
          x="28.471"
          y="33.211"
          width="81.7238"
          height="38.1378"
          rx="5"
          transform="rotate(20 28.471 33.211)"
          fill="#5522AA"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_20">
        <rect width="100" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
